import NavigateBeforeIcon from "@material-ui/icons/NavigateBefore";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";
import React from "react";
import { useSelector } from "react-redux";
import ReactSelectBox from "src/components/core/ReactSelectBox";
import {
  MILK_ENTRY_BY_BOTH,
  MILK_ENTRY_BY_CODE,
  MILK_ENTRY_BY_NAME,
} from "src/_constants";
import { useLanguage } from "src/components/language/LanguageController";
import { getAddMilkHowToSelectCustomer } from "src/reducers/dairySettings";

const CustomerSelection = ({
  code,
  customerCodeRef,
  customerSelectRef,
  handleCodeChange,
  customers,
  selectedCustomer,
  customerChangeHandler,
  dairyCustomerId,
  validator = null,
}) => {
  const { t } = useLanguage();
  const howToSelectCustomer = useSelector(getAddMilkHowToSelectCustomer);

  const availableCodes = customers
    .map((customer) => parseInt(customer.code))
    .sort((a, b) => a - b);

  const firstAvailableCode = availableCodes[0];
  const lastAvailableCode = availableCodes[availableCodes.length - 1];

  const findNearestCode = (currentCode, direction) => {
    let nearestCode = currentCode;
    while (!availableCodes.includes(nearestCode)) {
      nearestCode += direction;
      if (nearestCode < firstAvailableCode || nearestCode > lastAvailableCode) {
        return currentCode;
      }
    }
    return nearestCode;
  };

  const incrementCode = () => {
    const nextCode = findNearestCode(parseInt(code) + 1, 1);
    handleCodeChange({ target: { value: nextCode } });
  };

  const decrementCode = () => {
    const prevCode = findNearestCode(parseInt(code) - 1, -1);
    handleCodeChange({ target: { value: prevCode } });
  };
  return (
    <div className="form-row customer-selection-container">
      {howToSelectCustomer !== MILK_ENTRY_BY_NAME && (
        <div className="col">
          <div className="form-group code-block">
            <label className="m-0" htmlFor="customer_code">
              {t("text_code")}
            </label>
            <div className="input-group code-input-button">
              <div className="input-group-prepend">
                <button
                  type="button"
                  className="btn btn-decrement btn-outline-secondary border-secondary-light"
                  onClick={decrementCode}
                  disabled={
                    howToSelectCustomer === MILK_ENTRY_BY_NAME ||
                    parseInt(code) === firstAvailableCode
                  }
                >
                  <NavigateBeforeIcon />
                </button>
              </div>
              <input
                className="form-control code-input text-center"
                type="number"
                id="customer_code"
                defaultValue={code}
                ref={customerCodeRef}
                onChange={(e) => {
                  e.persist();
                  handleCodeChange(e);
                }}
                readOnly={howToSelectCustomer === MILK_ENTRY_BY_NAME}
                tabIndex={howToSelectCustomer === MILK_ENTRY_BY_NAME && -1}
              />
              <div className="input-group-append">
                <button
                  type="button"
                  className="btn btn-decrement btn-outline-secondary border-secondary-light"
                  onClick={incrementCode}
                  disabled={
                    howToSelectCustomer === MILK_ENTRY_BY_NAME ||
                    parseInt(code) === lastAvailableCode
                  }
                >
                  <NavigateNextIcon />
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
      <div className="col">
        <div className="form-group select-customer-group">
          <label className="m-0" htmlFor="input_dairy_customer_id">
            {t("text_customer")}
          </label>
          <div className="select-custumer-container">
            <ReactSelectBox
              noOptionsMessage={() => t("text_no_options")}
              placeholder={t("text_select_customer") + "..."}
              options={customers}
              name="dairy_customer_id"
              inputId="input_dairy_customer_id"
              value={selectedCustomer}
              onChange={customerChangeHandler}
              isDisabled={howToSelectCustomer === MILK_ENTRY_BY_CODE}
              tabIndex={howToSelectCustomer === MILK_ENTRY_BY_CODE && -1}
              ref={customerSelectRef}
              className="customer-select"
            />
          </div>
          {validator.message("add_milk_customer", dairyCustomerId, "required")}
        </div>
      </div>
    </div>
  );
};

export default CustomerSelection;
