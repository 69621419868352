// @ts-nocheck
import { RATE_CHART_SETUP_TYPES } from "src/_constants/rateList";
import { getLang, t } from "../language/LanguageController";
import DairyFormula from "../pages/dairy/RateList_new/DairyFormula";
import { getMilkRateBy } from "../library/milkUtils";

export const mapRateListTypesToSelect = (rateListTypes = []) => {
  if (rateListTypes && rateListTypes.length) {
    return rateListTypes.map((rateListType) => ({
      ...rateListType,
      value: rateListType.rate_list_type_id,
      label: getRateListTypeLabel(rateListType),
    }));
  }

  return [];
};

export const getRateListTypeLabel = (rateListType) => {
  return rateListType?.name?.[getLang()] || rateListType?.name?.["en"];
};

export const getSelectedRateListType = (rateListTypes, rateListTypeId) => {
  const selectedRateListType = rateListTypeId
    ? rateListTypes.find(
        (rateListType) => rateListType.rate_list_type_id == rateListTypeId
      )
    : rateListTypes[0];
  if (selectedRateListType) {
    selectedRateListType.label = getRateListTypeLabel(selectedRateListType);
    selectedRateListType.value = selectedRateListType.rate_list_type_id;
  }
  return selectedRateListType || {};
};

export const isRateBySNF = (rateListTypeId) => {
  rateListTypeId = parseInt(rateListTypeId);
  return [8, 9, 11].includes(rateListTypeId);
};

export const getRateChartColumnsRows = (rateListTypeId, settings = {}) => {
  let i = 2.5,
    rows = [],
    hColumns = [];
  try {
    rateListTypeId = parseInt(rateListTypeId);
    let dairyFormula = new DairyFormula(
      rateListTypeId,
      settings.milk_rate,
      settings.snf_rate,
      settings.snf_formula,
      settings.cow_std_snf,
      settings.buffalo_std_snf,
      settings.clr_rate,
      settings.is_deduction,
      settings
    );

    while (i <= 15) {
      i = i.toFixed(1);
      let columns = [i];
      if ([9, 11].indexOf(rateListTypeId) > -1 || settings.rate_by === "snf") {
        let j = 7.5;
        while (j <= 9.5) {
          j = parseFloat(j.toFixed(1));
          let clData = dairyFormula.calculateRate(i, j);
          if (hColumns.indexOf(j) === -1) {
            hColumns.push(j);
          }
          columns.push(clData);
          j += 0.1;
        }
      } else {
        for (let j = 30; j >= 18; j--) {
          let clData = dairyFormula.calculateRate(i, j);
          if (hColumns.indexOf(j) === -1) {
            hColumns.push(j);
          }
          columns.push(clData);
        }
      }

      rows.push(columns);
      i = parseFloat(i) + 0.1;
    }
  } catch (error) {
    console.log(error);
  }

  return {
    rows: rows,
    columns: hColumns,
  };
};

export const mapRateListsToSelect = (rateLists = []) => {
  if (rateLists && rateLists.length) {
    return rateLists.map((rateList) => ({
      ...rateList,
      value: rateList.rate_list_id,
      label: getRateListLabel(rateList),
    }));
  }

  return [];
};

export const getRateListLabel = (rateList) => {
  return rateList?.name;
};

export const getSelectedRateList = (rateLists, rateListId) => {
  const selectedRateList = rateLists.find(
    (rateList) => rateList.rate_list_id == rateListId
  );
  if (selectedRateList) {
    selectedRateList.label = getRateListLabel(selectedRateList);
    selectedRateList.value = selectedRateList.rate_list_id;
  }
  return selectedRateList;
};

export const getCustomerRateList = (rateLists, rateListId, customerType) => {
  let rateList = rateLists[0];
  if (rateLists.length > 1 && customerType === "buyer") {
    // If more then one rate lists are available and customer type is buyer
    // then select second rate list as buyer rate list
    rateList = rateLists[1];
  }
  if (rateListId) {
    const rateListById = rateLists.find(
      (rateList) => rateList.rate_list_id == rateListId
    );
    if (rateListById) {
      rateList = rateListById;
    }
  }
  return rateList;
};

export const getRateListSettings = (rateList, cattle) => {
  const rateChartBy =
    rateList?.rate_chart_setup_type === "by_cattle"
      ? cattle
      : rateList?.rate_chart_setup_type;
  const rateListTypeId = parseInt(
    rateList?.configuration?.[rateChartBy]?.rate_list_type_id || 0
  );
  const rateListSetting =
    rateList?.configuration?.[rateChartBy]?.[rateListTypeId] || {};
  return {
    rateChartBy,
    rateListTypeId: parseInt(rateListTypeId),
    rateListSetting,
  };
};

export const calculateRate = (
  rateLists,
  rateListId,
  {
    customerType,
    liter,
    fat,
    rate_by,
    clr,
    snf,
    cattle,
    personalRate,
    rateType,
  }
) => {
  const rateList = getCustomerRateList(rateLists, rateListId, customerType);
  const { rateListTypeId, rateListSetting } = getRateListSettings(
    rateList,
    cattle
  );
  const rateBy = rate_by
    ? rate_by
    : getMilkRateBy(rateListTypeId, rateListSetting);
  const dairyFormula = new DairyFormula(
    rateListTypeId,
    rateListSetting.milk_rate,
    rateListSetting.snf_rate,
    rateListSetting.snf_formula,
    rateListSetting.cow_std_snf,
    rateListSetting.buffalo_std_snf,
    rateListSetting.clr_rate,
    rateListSetting.is_deduction,
    rateListSetting
  );
  const calculatedRate = dairyFormula.calculateRate(
    parseFloat(fat),
    parseFloat(rate_by === "clr" ? clr : snf),
    rateType,
    parseFloat(liter)
  );

  // Override rate with customer personal rate
  if (personalRate && personalRate[cattle]) {
    calculatedRate.rate = personalRate[cattle];
  }

  return calculatedRate;
};

export const getRateChartSetUpTypes = () => {
  return RATE_CHART_SETUP_TYPES.map((type) => ({
    ...type,
    label: t(type.label),
  }));
};

export const getRateListName = (nameJson) => {
  try {
    const lang = getLang();
    const parsedName = JSON.parse(nameJson);
    return parsedName?.[lang] || parsedName?.["en"];
  } catch {}

  return nameJson;
};
