// @ts-nocheck
import { Tooltip as MuiTooltip } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
import MessageIcon from "@material-ui/icons/Message";
import PrintIcon from '@material-ui/icons/Print';
import SaveIcon from '@material-ui/icons/Save';
import WhatsAppIcon from '@material-ui/icons/WhatsApp';
import _ from 'lodash';
import moment from "moment";
import React, { Component } from 'react';
import { Icon, Switch } from 'react-onsenui';
import Tooltip from 'react-power-tooltip';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { DefaultLayout } from 'src/components/pages/common/Layout';
import { getAddMilkHowToSelectCustomer, getMilkEntryAutoPrint} from 'src/reducers/dairySettings';
import { DAIRY_CUSTOMER_CODE_DEBOUNCE, MILK_ENTRY_BY_NAME } from '../../../../../_constants';
import { ABILITY_MILK_COLLECTION, PERMISSION_DELETE, PERMISSION_PRINT_AND_SEND_SMS, PERMISSION_UPDATE } from '../../../../../_constants/permissions';
import { fetchDairyTodayTotal } from '../../../../../actions/dairy';
import { fetchRateLists } from "../../../../../actions/dairyRateList";
import config from "../../../../../config";
import { HasPermission } from '../../../../common/AccessPermission';
import { calculateFeatureMinDate, checkFeatureInSubscription, getFeatureLimit } from '../../../../common/FeatureFlag/FeatureFlag';
import MuiDatepicker from "../../../../common/MuiDatepicker";
import SimpleValidator from '../../../../common/SimpleValidator';
import { PrimaryButton } from '../../../../common/buttons/PrimaryButton';
import { RedButton } from '../../../../common/buttons/RedButton';
import { InputText } from '../../../../core/input';
import { calculateRate, getCustomerRateList, getRateListSettings } from "../../../../helpers/rateListHelper";
import withLanguage from '../../../../language/LanguageController';
import Eventer from "../../../../library/Eventer";
import { isConnected } from '../../../../library/bluetoothPrinter';
import { getCustomerForCollection, getCustomersForSelect, getDefaultSelectedCustomer, getSelectedCustomerForSelect } from '../../../../library/customer';
import { getBillingStartEndDate } from '../../../../library/dateUtils';
import { isAndroid } from '../../../../library/detect-device';
import { captureException } from "../../../../library/errorTracking";
import analytics from '../../../../library/firebase/analytics';
import { checkIsCLRSNFEnabled, getDefaultCLRSNF, getMilkCollectionEntrySettings, getMilkRateBy, getRateListMilkTypes, getShift, isFatEnabled } from '../../../../library/milkUtils';
import request from '../../../../library/request';
import { errorHandler } from '../../../../library/response';
import smsApp from '../../../../library/smsApp';
import { milkSMSTemplate } from '../../../../library/smsTemplates';
import { confirmPop, loader, toastBottom } from "../../../../library/util";
import NoCustomerModal from "../../../../modalPopups/noCustomerModal";
import DairyModel from '../../../../models/Dairy';
import { milkDetailReceipt } from '../../../../templates/printerTemplates';
import InnerNav from '../../InnerNav';
import BluetoothPrinter from '../../modalPopup/BluetoothPrinter';
import AddMilkCLRSNF from './AddMilkCLRSNF';
import AddMilkSNFCLR from './AddMilkSNFCLR';
import CattleSelection from './CattleSelection';
import CustomerSelection from './CustomerSelection';
import ShiftSection from './ShiftSection';
import { selectDairyCustomers } from 'src/reducers/dairyCustomer';

class AddMilk extends Component {
  constructor(props) {
    super(props);
    this.fetchMilkEntries = this.fetchMilkEntries.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.deleteMilk = this.deleteMilk.bind(this);
    this.handleCodeChange = _.debounce(this.handleCodeChange.bind(this), DAIRY_CUSTOMER_CODE_DEBOUNCE);
    this.validator = new SimpleValidator();
    this.dairy = new DairyModel();
    this.customerCodeRef = React.createRef();
    this.customerSelectRef = React.createRef();
    this.state = {
      editMilkCustomerId: this.props.location?.state?.dairy_customer_id,
      ...this.getInitialState()
    }
  }

  getInitialState = () => {
    const today = moment();
    const { customers } = this.props;
    const customersForSelect = getCustomersForSelect(customers.filter(customer => customer.status == 1));
    const selectedCustomer = getDefaultSelectedCustomer(customersForSelect);
    const { dairyCustomerId, cattle, milkAnimals, code, personalRate, type: customerType, rate_list_id } = selectedCustomer;
    const editDate = this.props.location?.state?.date;
    const rateListState = this.getRateListRaltedState(rate_list_id, customerType, cattle);
    const editCattle = this.props.location?.state?.cattle;
    const editShift = this.props.location?.state?.shift;
    const rateEditable = this.props.dairyPreference.rate_editable?.value ? true:false;
    return {
      date: editDate ? moment(editDate) : today,
      maxDate: today,
      minDate: calculateFeatureMinDate("add_old_milk"),
      oldMilkLimit: getFeatureLimit("add_old_milk"),
      old_milk: false,
      shift: editShift ? editShift : getShift(),
      customers: customersForSelect,
      dairyCustomerId: dairyCustomerId,
      selectedCustomer: selectedCustomer,
      milkAnimals: milkAnimals,
      cattle: editCattle ? editCattle : cattle,
      code: code,
      literHelp: false,
      fatHelp: false,
      clrHelp: false,
      snfHelp: false,
      personalRate: personalRate,
      inputReadOnly: false,
      isBSetting: false,
      printers: [],
      is_saving: false,
      disable_morning: false,
      disable_evening: false,
      isAndroid: false,
      isEdit: false,
      rate_editable: rateEditable,
      ...rateListState,
      ...this.getDefaultMilkState(cattle, rateListState.rateListSetting)
    }
  }

  getRateListRaltedState = (rateListId, customerType, cattle) => {
    const { rateLists } = this.props;
    const rateList = getCustomerRateList(rateLists, rateListId, customerType);
    const {
      rateListTypeId,
      rateListSetting,
    } = getRateListSettings(rateList, cattle);
    return {
      rate_by: getMilkRateBy(rateListTypeId, rateListSetting),
      isCLRSNFEnabled: checkIsCLRSNFEnabled(rateListTypeId, rateListSetting),
      isFatEnabled: isFatEnabled(rateListTypeId, rateListSetting),
      milkTypes: getRateListMilkTypes(rateListSetting),
      rateListTypeId,
      rateListSetting,
    }
  }

  getDefaultMilkState = (cattle = "", rateListSetting) => {
    const { defaultClr, defaultSnf } = getDefaultCLRSNF(rateListSetting)
    return {
      liter: '',
      fat: '',
      clr: defaultClr,
      snf: defaultSnf,
      defaultClr: defaultClr,
      defaultSnf: defaultSnf,
      rate: 0,
      total: '',
      rate_type: 1
    }
  } 

  componentDidMount() {
    analytics.setScreenName("AddMilk");
    this.fetchMilkEntries();
    Eventer.on("milkDeleted", this.onMilkDeleted);
    Eventer.on("editMilk", this.editCustomerMilk);
    this.updateCollectionFormForCustomer();
    if(this.props.rateLists.length <= 0) {
      this.props.fetchRateLists(this.props.dairyId);
    }
    this.setFocus();
  }

  setFocus = (clearCode = false) => {
    const { howToSelectCustomer } = this.props;
    console.log("howToSelectCustomer", howToSelectCustomer);
    if(howToSelectCustomer !== MILK_ENTRY_BY_NAME) {
      this.customerCodeRef?.current?.focus();
      this.customerCodeRef.current.value = "";
    } else {
      this.customerSelectRef.current.focus();
    }
  }
 
  componentDidUpdate(prevProps) {
    if(this.props.rateLists.length !== prevProps.rateLists.length) {
      const { cattle, selectedCustomer } = this.state;
      const { type: customerType, rate_list_id } = selectedCustomer;
      const rateListState = this.getRateListRaltedState(rate_list_id, customerType, cattle);
      this.setState({
        ...rateListState
      });
    }

    if(this.props.howToSelectCustomer !== prevProps.howToSelectCustomer) {
      this.setFocus();
    }
  }

  componentWillUnmount() {
    Eventer.off("milkDeleted", this.onMilkDeleted);
    Eventer.off("editMilk", this.editCustomerMilk);
  }

  editCustomerMilk = (event) => {
    const { dairyCustomerId, date, cattle = this.state.cattle, shift = this.state.shift } = event.detail;
    const customer = this.props.customers.find(customer => customer.dairy_customer_id === dairyCustomerId);
    if(customer) {
      this.validator.clearAll();
      this.validator.purgeFields();
      const customerForSelect = getSelectedCustomerForSelect(customer);
      const customerForCollection = getCustomerForCollection(customer);
      const editDate = date ? moment(date) : this.state.date;
      const isDateSame = this.state.date.isSame(date);
      const rateListState = this.getRateListRaltedState(customer.rate_list_id, customer.type, cattle);
      const selectedCustomer = {
        ...customer,
        ...customerForSelect,
        ...customerForCollection
      };
      let milkEntryState = {};
      if(isDateSame) {
        milkEntryState = this.getMilkEntryState(selectedCustomer, cattle, shift);
      }
      this.setState({
        date: editDate,
        isEdit: true,
        editMilkCustomerId: null,
        selectedCustomer: selectedCustomer,
        ...selectedCustomer,
        ...customerForCollection,
        ...rateListState,
        cattle,
        shift,
        ...milkEntryState
      }, () => {
        if(!isDateSame) {
          this.fetchMilkEntries();
        }
        this.customerCodeRef.current.value = customerForCollection.code;
      });
    }
  }

  onMilkDeleted = () => {
    this.setState({
      isEdit: false
    }, () => 
      this.fetchMilkEntries()
    );
  }

  showHelp = (e, type) => {
    e.stopPropagation();
    analytics.logEvent("showHelp");
    this.setState({
      [type + "Help"]: !this.state[type + "Help"],
    });
  }

  hideHelp = () => {
    if(this.state.literHelp || this.state.fatHelp || this.state.clrHelp || this.state.snfHelp) {
      this.setState({
        literHelp: false,
        fatHelp: false,
        clrHelp: false,
        snfHelp: false
      });
    }
  }

  async fetchMilkEntries () {
    try {
      loader.show(this.props.t("text_loading"));
      const { date, editMilkDate }  = this.state;
      const dateFormated = editMilkDate ? editMilkDate.format(config.db_date_format) : date.format(config.db_date_format);
      const { status, data } = await request.get('/dairies/' + this.props.dairyId + '/customers/milk', {
        params: {
          start_date: dateFormated,
          end_date: dateFormated
        },
        withAuth: true,
        version: "v2"
      });
      if(status === true) {
        this.setState({
          milkEntries: data
        }, () => {
          if(this.state.editMilkCustomerId) {
            this.editCustomerMilk({
              detail: {
                dairyCustomerId: this.state.editMilkCustomerId
              }
            })
          } else {
            this.setEditMilkState();
          }
        });
      }
    } catch (error) {
      errorHandler(error);
    } finally {
      loader.hide();
    }
  }

  async deleteMilk (e) {
    e.preventDefault();
    try {
      analytics.logEvent("deleteMilk");
      const { date, cattle, shift, dairyCustomerId } = this.state;
      if(!dairyCustomerId) {
        return false;
      }
      const confirm = await confirmPop(this.props.t("text_delete_milk_confirm"));
      if(confirm == 1) {
        loader.show(this.props.t("text_deleting"));
        const { dairyId } = this.props;
        
        const { status, message } = await request.delete('/dairies/' + dairyId + '/milk/' + dairyCustomerId, {
          withAuth: true,
          version: "v2",
          body: JSON.stringify({
            date: date,
            milk_type: cattle,
            day_time: shift === "morning" ? 0 : 1
          })
        });
        if (status) {
          toastBottom(message);
          Eventer.emit("milkDeleted", {
            cattle: this.state.cattle,
            shift: this.state.shift === "morning" ? 0 : 1,
            date: this.state.date.toString(),
            customerType: this.state.type
          });
        } else {
          throw message;
        }
      }
    } catch (error) {
      errorHandler(error);
    } finally {
      loader.hide();
    }
  }

  setEditMilkState = () => {
    this.validator.clearAll();
    this.validator.purgeFields();
    let { milkEntries, selectedCustomer, isEdit, customers: customersForSelect, dairyCustomerId } = this.state;
    const { customers } = this.props;
    let cattle = this.state.cattle;
    if(isEdit) {
      const editMilkCustomerIds = milkEntries.map(milkEntry => milkEntry.value);
      const editMilkCustomers = customers.filter(customer => editMilkCustomerIds.includes(customer.dairy_customer_id));
      customersForSelect = getCustomersForSelect(editMilkCustomers)
      if(!editMilkCustomerIds.includes(dairyCustomerId)) {
        selectedCustomer = getDefaultSelectedCustomer(customersForSelect);
        cattle = selectedCustomer.cattle;
      }
    }
    const rateListState = this.getRateListRaltedState(selectedCustomer.rate_list_id, selectedCustomer.type, cattle);
    this.setState({
      customers: customersForSelect,
      selectedCustomer: selectedCustomer,
      ...selectedCustomer,
      ...this.getMilkEntryState(selectedCustomer),
      ...rateListState
    }, () => {
      // this.customerCodeRef.current.value = selectedCustomer.code;
    });
  }

  handleInput = (e) => {
    let { name, value } = e.target;
    name = (name === "milk_type") ? "cattle" : (
      (name === "type") ? "shift" : name
    )
    const stateData = {
      [name]: value
    };

    if(name === "rate") {
      stateData.total = (this.state.liter * value).toFixed(2);
    }

    this.setState(stateData, () => {
      if(["date", "cattle", "shift"].includes(name)) {
        this.validator.clearAll();
        if(name === "date") {
          this.fetchMilkEntries();
        } else {
          this.setState({
            ...this.getMilkEntryState(this.state.selectedCustomer)
          }, () => {
            if(name === "cattle") {
              this.validator.clearAll();
              this.validator.purgeFields();
              this.updateCollectionFormForCustomer();
            }
          });
        }
      } else if(name !== "rate") {
        this.calculateRate();
      }
    });
  }

  validateField = e => {
    if(this.validator.fieldValid(e.target.name)) {
      this.validator.hideMessageFor(e.target.name);
    } else {
      this.validator.showMessageFor(e.target.name);
    }
    this.forceUpdate();
  }

  customerChangeHandler = customer => {
    this.validator.clearAll();
    this.validator.purgeFields();
    const selectedCustomer = {
      ...customer,
      ...getCustomerForCollection(customer)
    };
    this.setState({
      selectedCustomer: selectedCustomer,
      ...selectedCustomer,
      ...this.getMilkEntryState(selectedCustomer),
    }, () => {
      if(this.props.howToSelectCustomer !== MILK_ENTRY_BY_NAME){
        this.customerCodeRef.current.value = selectedCustomer.code
      }
      this.updateCollectionFormForCustomer();
    });
  }

  updateCollectionFormForCustomer = () => {
    this.validator.purgeFields();
    const { clr, snf, selectedCustomer, cattle } = this.state;
    const milkCollectionEntrySettings = getMilkCollectionEntrySettings(this.props.rateLists, {
      rateListId: selectedCustomer.rate_list_id,
      customerType: selectedCustomer.type,
      cattle: cattle
    });
    this.setState({
      ...milkCollectionEntrySettings,
      clr: clr ? clr : milkCollectionEntrySettings.defaultClr,
      snf: snf ? snf : milkCollectionEntrySettings.defaultSnf
    });
  }

  getMilkEntryState = (selectedCustomer, cattle = false, shift = false) => {
    const { dairyCustomerId } = selectedCustomer;
    const { milkEntries = [], isEdit } = this.state;
    const milkEntry = milkEntries ? milkEntries.find(milkEntry => milkEntry.value === dairyCustomerId) : false;
    let milkAnimals = [];
    let disable_evening = false;
    let disable_morning = false;
    cattle = cattle || this.state.cattle;
    shift = shift || this.state.shift;
    if(isEdit && milkEntry) {
      if("cow" in milkEntry){
        milkAnimals.push("cow");
      }
      if("buffalo" in milkEntry){
        milkAnimals.push("buffalo");
      }
    } else {
      milkAnimals = selectedCustomer.milkAnimals;
    }
    
    if(milkAnimals && !milkAnimals.includes(this.state.cattle)) {
      cattle = milkAnimals[0];
    }
    const cattleMilk = milkEntry ? milkEntry[cattle] : null;
    if(isEdit && cattleMilk) {
      if(shift === "morning" && cattleMilk.liter_0 == null) {
        shift = "evening"
      } else if(shift === "evening" && cattleMilk.liter_1 == null) {
        shift = "morning"
      }
      if(cattleMilk.liter_0 == null) {
        disable_morning = true;
      }
      if(cattleMilk.liter_1 == null) {
        disable_evening = true;
      }
    }
    const shiftNumber = shift === "morning" ? 0 : 1;

    const rateListId = selectedCustomer.rate_list_id

    const rateList = getCustomerRateList(this.props.rateLists, rateListId, selectedCustomer.type);
    const { rateListSetting } = getRateListSettings(rateList, cattle);
    
    const milkData = this.getMilkForShiftFromEntry(cattleMilk, shiftNumber, rateListSetting)
    return {
      cattle: cattle,
      shift: shift,
      milkAnimals,
      disable_morning,
      disable_evening,
      inputReadOnly: isEdit ? milkData.edited === "1" : milkData.liter > 0,
      ...milkData
    }
  }

  getMilkForShiftFromEntry = (milkEntry = {}, shift, rateListSetting = false) => {
    let defaultCLR = "";
    let defaultSNF = "";
    if(rateListSetting?.rate_settings) {
      defaultCLR = rateListSetting?.rate_settings?.default_clr_value;
      defaultSNF = rateListSetting?.rate_settings?.default_snf_value;
    }
    return {
      liter: milkEntry?.[`liter_${shift}`] || "",
      fat: milkEntry?.[`fat_${shift}`] || "",
      clr: milkEntry?.[`clr_${shift}`] || defaultCLR,
      snf: milkEntry?.[`snf_${shift}`] || defaultSNF,
      rate: milkEntry?.[`rate_${shift}`] || "",
      total: milkEntry?.[`total_${shift}`] || "",
      rate_type: milkEntry?.[`rate_type_${shift}`] || 1,
      edited: "0"
    }
  }

  handleCodeChange(event) {
    const code = event.target.value;
    const customer = this.state.customers.find(customer => customer.code == code);
    if(customer) {
      this.customerChangeHandler(customer);
    } else {
      const rateListState = this.getRateListRaltedState(null, "seller", this.state.cattle);
      const milkSatate = this.getDefaultMilkState(this.state.cattle, rateListState.rateListSetting);
      this.setState({
        selectedCustomer: {
          label: this.props.t("text_no_customer_for_code"),
          value: null
        },
        dairyCustomerId: null,
        ...rateListState,
        ...milkSatate
      });
    }
  }

  dateChangeHandler = selectedDate => {
    const { shift } = this.state;
    const isSameDate = moment().isSame(selectedDate, "day");
    this.validator.clearAll();
    this.setState({
      date: selectedDate,
      type: (isSameDate && shift === "evening") ? "morning" : shift,
      disable_evening: isSameDate ? true : false
    }, () => {
      this.fetchMilkEntries();
    });
  }

  calculateRate = () => {
    const { cattle, rate_by, liter, fat, personalRate, rate_list_id, type, clr, snf, rate_type } = this.state;
    const { rateLists } = this.props;
    const calculatedRate = calculateRate(rateLists, rate_list_id, {
      customerType:type,
      liter,
      fat,
      clr,
      snf,
      rate_by,
      cattle,
      personalRate,
      rateType: rate_type - 1
    });
    const clrSnfKey = rate_by === "snf" ? "clr" : "snf";
    this.setState({
      rate: (calculatedRate.rate).toFixed(2) || "",
      total: (this.state.liter * calculatedRate.rate).toFixed(2),
      [clrSnfKey]: calculatedRate?.[clrSnfKey] || ""
    });
  }
  
  async handleSubmit(e) {
    e.preventDefault();
    try {
      // Validate form before submitting to server
      if (!this.validator.allValid()) {
        this.validator.showMessages();
        this.forceUpdate(); // Rerender to show messages for the first time
        return false;
      }
  
      analytics.logEvent(this.state.isEdit ? "editMilk" : "addMilk");
      loader.show(this.props.t("text_saving"));
      this.setState({
        is_saving: true,
      });
  
      let formData = new FormData(document.getElementById("addMilkForm"));
  
      if (typeof formData.delete === "function") {
        formData.delete("dairy_customer_id");
      }
  
      if (!formData.has("type")) {
        formData.append("type", this.state.shift);
      }
  
      let action = "add";
      if (this.state.isEdit) {
        formData.append("edited", 1);
        action = "update";
      }
  
      const { status, message, error_code } = await request.post(
        `/dairies/${this.props.dairyId}/milk/${action}/${this.state.dairyCustomerId}`,
        {
          withAuth: true,
          body: formData,
          version: "v3",
        }
      );
  
      if (status === true) {
        toastBottom(message);
        this.setState(
          {
            inputReadOnly: true,
          },
          () => {
            this.fetchMilkEntries();
          }
        );
  
        Eventer.emit(this.state.isEdit ? "milkEdited" : "milkAdded", {
          cattle: this.state.cattle,
          shift: this.state.shift === "morning" ? 0 : 1,
          date: this.state.date.toString(),
          customerType: this.state.type,
        });
  
        this.setFocus(true);
  
        const { autoPrintEnabled } = this.props;
        if (autoPrintEnabled) {
          this.printReceipt();
        }
      } else if (error_code !== 501) {
        throw message;
      }
    } catch (error) {
      errorHandler(error);
    } finally {
      loader.hide();
      this.setState({
        is_saving: false,
      });
    }
  }
  

  handleOldMilkChange = e => {
    analytics.logEvent("oldMilk");
    if(!checkFeatureInSubscription("add_old_milk")) {
      this.setState({
        old_milk: false
      });
      return false;
    }
    const currentHour = moment().format("HH");
    let checked = e.target.checked,
        date = moment();

    if(checked && currentHour < 15){
      date = date.subtract(1, 'days');
    }
    const isSameDate = moment().isSame(date, "day");
    let shift = (!checked && currentHour >= 15) ? "evening" : "morning";
    this.validator.clearAll();
    this.setState({
      date: date,
      old_milk: checked,
      shift: shift,
      disable_evening: checked ? isSameDate : false
    }, () => {
      this.fetchMilk();
    });
  }

  handleEditMilk = e => {
    const { checked } = e.target;
    const defaultState = (!checked) ? this.getInitialState() : {};
    this.setState({
      isEdit: checked,
      ...defaultState
    }, () => this.fetchMilkEntries());
  }

  getMilkTotal = async () => {
    try {
      const { date, dairyCustomerId } = this.state;
      const { dairyId, billingCycle } = this.props;
      const { startDate, endDate } = getBillingStartEndDate(billingCycle, date);
      let filterStr = `?date=${startDate.format(config.date_format)}:${endDate.format(config.date_format)}`;
      
      const { status, data, message } = await request.get(`/dairies/${dairyId}/milk/${dairyCustomerId}/totalSmsPrint${filterStr}`, {
        withAuth: true,
        version: "v2"
      });
      if(status) {
        return data.total;
      } else {
        throw message;
      }
    } catch (error) {
      throw error;
    }
  }

  sendTextSMS = async e => {
    e.preventDefault();
    analytics.logEvent("sendSMS");
    if(checkFeatureInSubscription("send_sms")) {
      try {
        const { selectedCustomer: { c_mobile } }  = this.state;
        const message = await this.getMessageText();
        smsApp.sendTextSms(c_mobile, message);
      } catch (error) {
        captureException(error);
      }
    }
  }

  sendWhatsappSMS = async e => {
    e.preventDefault();
    analytics.logEvent("sendSMS");
    if(checkFeatureInSubscription("send_sms")) {
      try {
        const { selectedCustomer: { c_mobile } }  = this.state;
        const message = await this.getMessageText();
        smsApp.sendWhatsappSms(c_mobile, message);
      } catch (error) {
        captureException(error);
      }
    }
  }

  getMessageText = async () => {
    const total = await this.getMilkTotal();    
    const { dairyPreference }  = this.props;
    return milkSMSTemplate({...this.state, billTotal: total},dairyPreference);  
  }

  printReceipt = e => {
    analytics.logEvent("printReceipt");
    (async () => {
      try {
        
        if(checkFeatureInSubscription("print")) {
          loader.show(this.props.t("text_printing"));
          const connected = await isConnected();
          const billTotal = await this.getMilkTotal();
          if(connected) {
            const { 
              selectedCustomer: { name: customerName, code }, 
              date,
              liter,
              fat,
              snf,
              clr,
              rate,
              total,
              shift,
              cattle
            } = this.state;
            
            const { dairyName, dairyPreference } = this.props;
            await milkDetailReceipt(
              dairyName,
              customerName,
              code,
              date.format(config.date_format),
              liter,
              fat,
              clr,
              snf,
              rate,
              total,
              shift,
              cattle,
              billTotal,
              dairyPreference
            );
          } else {
            this.setState({
              isBSetting: true
            });
          }
        }
      } catch (error) {
        errorHandler(error);
      } finally {
        loader.hide();
      }
    })();
  }

  togglePrinterModel = () => {
    this.setState({
      isBSetting: !this.state.isBSetting
    });
  }

  renderFixedContent = () => {
    const { isBSetting } = this.state;
    const { t } = this.props;
    if(isBSetting) {
      return (
        <>
          <BluetoothPrinter
            t={t} 
            isOpen={isBSetting}
            onClose={this.togglePrinterModel}
          />
          <NoCustomerModal navigator={this.props.navigator} />
        </>
      );
    } else {
      return <NoCustomerModal navigator={this.props.navigator} />;
    }
  }

  onDeviceChange = deviceTypes => 
    this.setState({ ...deviceTypes });

  render() {
    const { t, header } = this.props;
    const isAndroidDevice = isAndroid();
    const {
      rate_by,
      is_saving,
      isCLRSNFEnabled,
      isFatEnabled,
      disable_morning,
      disable_evening,
      milkAnimals,
      cattle,
      shift,
      selectedCustomer,
      customers,
      milkTypes,
      rateListTypeId,
      rateListSetting,
      isEdit,
      oldMilkLimit
    } = this.state;
    return (
      <DefaultLayout
        onClick={this.hideHelp}
        title={isEdit ? t("text_edit_milk") : t("text_add_milk")}
        toolbar={false}
        header={header}
        bottomGutter={header}
        onDeviceChange={this.onDeviceChange}
        back>
          <div className="t-card shadow-sm">
            <form 
              id="addMilkForm"
              onSubmit={this.handleSubmit}
              className="position-relative"
              noValidate
            >
              <HasPermission ability={ABILITY_MILK_COLLECTION} access={PERMISSION_UPDATE}>
                <div className="position-absolute right-0 z-index-9">
                  {`${t("text_edit_milk")} `}&nbsp;
                  <Switch 
                    material="material" 
                    checked={this.state.isEdit}
                    onChange={this.handleEditMilk} />
                </div>
              </HasPermission>              
              <div className="form-group date-group">
                <label>{t("text_date")}</label>
                  <MuiDatepicker
                    value={this.state.date}
                    minDate={this.state.minDate}
                    maxDate={this.state.maxDate}
                    onChange={this.dateChangeHandler}
                    name="date"
                    minDateFeatureLimit={!oldMilkLimit || oldMilkLimit === "last 1 day" ? true : false}
                    className=""
                  />
                {this.validator.message('date', this.state.date, 'required')}
              </div>
              <ShiftSection
                shift={shift}
                disable_morning={disable_morning}
                disable_evening={disable_evening}
                handleInput={this.handleInput}
              />
              <CustomerSelection
                code={this.state.code}
                customerCodeRef={this.customerCodeRef}
                customerSelectRef={this.customerSelectRef}
                handleCodeChange={this.handleCodeChange}
                customers={customers}
                selectedCustomer={this.state.selectedCustomer}
                customerChangeHandler={this.customerChangeHandler}
                dairyCustomerId={this.state.dairyCustomerId}
                validator={this.validator}
              />
              <CattleSelection
                milk_animals={milkAnimals}
                cattle={cattle}
                handleInput={this.handleInput}
              />
              <div className="form-row">
                <div className="col">
                  <InputText 
                    label={
                      <span>
                        {t("text_liter")} &nbsp;
                        <Icon icon="md-help" onClick={e => this.showHelp(e, 'liter')}/>
                        <Tooltip 
                          show={this.state.literHelp}
                          position="top center">
                          <span>{t("text_liter_help")}</span>
                        </Tooltip>
                      </span>
                    }
                    type="number"
                    className="form-control input-bottom-border"
                    name="liter"
                    id="input_liter"
                    errorMessage={
                      this.validator.message('liter', this.state.liter, 'required|numeric|min:0.10,num|max:9999999.99,num')
                    }
                    value={this.state.liter}
                    min="0.10"
                    step="0.01"
                    max="9999999.99"
                    autoComplete="new-password"
                    readOnly={this.state.inputReadOnly}
                    onBlur={this.validateField}
                    onChange={this.handleInput}
                    placeholder="ex: 5.5" />
                </div>
                {milkTypes.length > 1 && (
                  <div className="col">
                    <InputText 
                      label={t("text_type")}
                      type="number" 
                      className="form-control input-bottom-border"
                      name="rate_type"
                      value={this.state.rate_type}
                      id="input_rate_type"
                      step="1"
                      readOnly={this.state.inputReadOnly}
                      autoComplete="new-password"
                      onChange={this.handleInput}
                      placeholder="ex: 1"/>
                  </div>
                )}
                {isFatEnabled ? (
                  <div className="col">
                    <InputText 
                      label={
                        <span>
                          {t("text_fat")} &nbsp;
                          <Icon icon="md-help" onClick={e => this.showHelp(e, 'fat')}/>
                          <Tooltip 
                            show={this.state.fatHelp}
                            position="top center">
                            <span>{t("text_fat_help")}</span>
                          </Tooltip>
                        </span>
                      }
                      type="number" 
                      className="form-control input-bottom-border"
                      name="fat"
                      id="input_fat"
                      value={this.state.fat}
                      min="2.5"
                      max="15"
                      step="0.1"
                      errorMessage={
                        this.validator.message('fat', this.state.fat, 'required|numeric|min:2.5,num|max:15,num')
                      }
                      readOnly={this.state.inputReadOnly}
                      autoComplete="new-password"
                      onBlur={this.validateField}
                      onChange={this.handleInput}
                      placeholder="ex: 6.5"/>
                  </div>
                ) : null}
                {isCLRSNFEnabled ? (
                  <AddMilkCLRSNF 
                    clr={this.state.clr}
                    clrHelp={this.state.clrHelp}
                    rateListTypeId={rateListTypeId}
                    handleInput={this.handleInput}
                    inputReadOnly={this.state.inputReadOnly}
                    rate_by={rate_by}
                    showHelp={this.showHelp}
                    snf={this.state.snf}
                    snfHelp={this.state.snfHelp}
                    validator={this.validator}
                    validateField={this.validateField}
                    rateSettings={rateListSetting}/>
                ) : null}
              </div>
              <div className="form-row">
                <div className="col">
                  <InputText
                    label={t("text_rate")}
                    type="number" 
                    className="form-control input-bottom-border"
                    name="rate"
                    id="input_rate"
                    value={this.state.rate}
                    readOnly={this.state.inputReadOnly||!this.state.rate_editable}
                    required
                    tabIndex={!this.state.rate_editable && "-1"}
                    autoComplete="new-password"
                    onChange={this.handleInput}
                    errorMessage={
                      this.validator.message('rate', this.state.rate, 'required|numeric')
                    } />
                </div>
                {isCLRSNFEnabled ? (
                  <AddMilkSNFCLR
                    clr={this.state.clr}
                    rateListTypeId={rateListTypeId}
                    handleInput={this.handleInput}
                    rate_by={rate_by}
                    snf={this.state.snf}/>
                ) : null}
                <div className="col">
                  <InputText
                    label={t("text_total")}
                    type="number"
                    className="form-control input-bottom-border"
                    name="total"
                    id="input_total"
                    value={this.state.total}
                    readOnly
                    required
                    tabIndex="-1"
                    autoComplete="new-password"
                    onChange={this.handleInput} />
                </div>
              </div>
              <div className="modal-footer pb-0 px-0 flex-column">
                <div className="d-flex w-100 justify-content-between mb-2">
                  <PrimaryButton
                    variant="contained"
                    type="submit" 
                    disabled={this.state.inputReadOnly || is_saving}
                    startIcon={<SaveIcon />}
                  >
                    {t("text_save")}
                  
                  </PrimaryButton>
                  <HasPermission ability={ABILITY_MILK_COLLECTION} access={PERMISSION_DELETE}>
                    <MuiTooltip title={t("text_delete")} placement="top">
                      <span>
                        <RedButton
                          variant="contained"
                          type="button"
                          disabled={!this.state.inputReadOnly && !this.state.isEdit}
                          onClick={this.deleteMilk}
                          startIcon={<DeleteIcon/>}
                        >
                          {t("text_delete")}
                        </RedButton>
                      </span>
                    </MuiTooltip>
                  </HasPermission>
                  </div>
                  <div className="d-flex w-100 justify-content-end mb-2">
                    <HasPermission ability={ABILITY_MILK_COLLECTION} access={PERMISSION_PRINT_AND_SEND_SMS}>
                      <IconButton
                        variant="contained"
                        disabled={!this.state.inputReadOnly}
                        onClick={this.printReceipt}
                        startIcon={<PrintIcon/>}
                      >
                        <PrintIcon/>
                      </IconButton>
                    </HasPermission>
                    <HasPermission ability={ABILITY_MILK_COLLECTION} access={PERMISSION_PRINT_AND_SEND_SMS}>
                      {isAndroidDevice && selectedCustomer.c_mobile ?  (
                        <>
                          <IconButton
                            type="button"
                            disabled={!this.state.inputReadOnly}
                            onClick={this.sendTextSMS}
                          >
                            <MessageIcon/>
                          </IconButton>
                          <IconButton
                            type="button"
                            disabled={!this.state.inputReadOnly}
                            onClick={this.sendWhatsappSMS}
                          >
                            <WhatsAppIcon/>
                          </IconButton>
                        </>
                      ) : null}
                    </HasPermission>
                  </div>
              </div>
            </form>
          </div>
          <InnerNav 
            menu="milk"
            page="addMilk"
            navigator={this.props.navigator}
          />
        {this.renderFixedContent()}
      </DefaultLayout>
    );
  }
}

AddMilk.displayName = "AddMilk";

AddMilk.defaultProps = {
  header: true
};

const mapStateToProps = state => {
  return {
    settings: state.dairySettings.settings,
    dairyPreference: state.dairySettings.dairyPreference,
    customers: selectDairyCustomers(state),
    dairyType: state.dairy.dairy.type,
    dairyId: state.dairy.dairy.id,
    dairyName: state.dairy.dairy.name,
    rateLists: state.dairyRateList.rateLists,
    billingCycle: state.dairy.dairy.billing_cycle,
    autoPrintEnabled: getMilkEntryAutoPrint(state),
    howToSelectCustomer: getAddMilkHowToSelectCustomer(state)
  }
}

const mapDispatchToProps = {
  fetchDairyTodayTotal,
  fetchRateLists
}

export default connect(mapStateToProps, mapDispatchToProps)(
  withRouter(withLanguage(AddMilk))
);